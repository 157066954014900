@import "src/main";

.top-area {
  background: $primary;
  height: 10rem;

  .row {
    height: 100%;
  }

  .items {
    height: 100%;
    text-align: center;

    .item {
      color: $fontColor3;
      width: 4rem;
      display: inline-block;

      &:first-child {
        .icon {
          width: 2.5rem;
        }
      }

      &:last-child {
        .icon {
          width: 1.9rem;
        }

      }
    }

    .icon {
      width: 2.7rem;
      margin-top: 2.3rem;
      margin-left: auto;
      margin-right: auto;
    }


    .text-uppercase {
      margin-top: .7rem;
      font-size: 13px;
      @extend .font-opens;
      font-weight: 500;
    }
  }

  .line {
    width: 4rem;
    height: 4px;
    display: inline-block;
    border-radius: 3rem;
    background: $bgColor1;
  }
}

.notice-tender-content {
  background: $bgColor2;
  height: 70%;
  width: 90%;
  @extend .font-opens;

  .left {
    color: $bgColor1;
    width: 78%;

    h3 {
      color: $primary;
      font-size: $fontSize2;
    }

    p {
      color: $primary;
      font-size: $fontSize1;
    }
  }
}

.mx-recent {
  margin: 0 2.3rem;
}

.px-recent {
  padding: 0 2.3rem;
}

.recent-heading {
  border-bottom: .5px solid $borderColor;

  .tagline {
    width: 38.2%;

    .ms-tag {
      margin-left: 2.3rem;
    }
  }
}

.recent-item {
  border-bottom: .5px solid $borderColor;

  .title {
    .alert-section {
      border-radius: .3rem;
      padding: 0 .27rem;
      font-size: 9px;
      color: $white;
      height: .9rem;
      margin-top: 3px;
    }

    .color-primary {
      background: $alertColor1;
    }

    .color-danger {
      background: $alertColor2;
    }

    .color-orange {
      background: $alertColor3;
    }

    h6 {
      font-size: .9rem;
      font-weight: 700;
      @extend .font-opens;
    }
  }

  .font-size13 {
    font-size: 13px;
  }

  .ms-recent-item {
    margin-left: 2.13rem;
  }
}

.postjob-alert {
  background: $color1;
  color: $white;

  h4 {
    color: $white;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    @extend .font-opens;
  }
}

.no-data-alert {
  .icon {
    width: 3rem;
    margin: auto;
  }

  .text-p {
    margin-top: .9rem;
    font-size: 13px;
    font-weight: 600;
  }
}

.mr-auto {
  margin: unset !important;
  margin-right: auto !important;
}

.btn-werf {
  width: 5.3rem;
}

@import "src/responsive";