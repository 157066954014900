@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$white: #fff;
$primary: #11333f;
$color1: #00BBB1;

$fontColor1: #393939;
$fontColor2: #222222;
$fontColor3: #f5f5f5;

$fontSize1: 14px;
$fontSize2: 18px;
$fontSize3: 1.3rem;

$bgColor1: #f0f2f5;
$bgColor2: #F0F2F5;
$bgColor3: #11333F;

$alertColor1: $color1;
$alertColor2: #FF5A5F;
$alertColor3: #EF7F1A;
$alertColor4: #AE4A84;
$alertColor5: #00A0E3;

$font1: 'Poppins', sans-serif;
$font2: 'Open Sans', sans-serif;
$font3: 'Roboto', sans-serif;

$borderColor: #dcdcdc;
$borderColor2: #bbb;

$borderRadius: .8rem;

@import "src/utils/mixin";

.main-content {
  background: $bgColor1;
  min-height: 100vh;
  margin: auto;
}

.pt-main {
  padding-top: 4rem;
}

.font-poppins {
  font-family: $font1 !important;
}

.font-opens {
  font-family: $font2 !important;
}

.rounded-main {
  border-radius: .8rem;
  overflow: hidden;
}

.rb {
  border-radius: 0 0 1rem 1rem;
}

.rt {
  border-radius: 1rem 1rem 0 0;
}

.MuiLinearProgress-colorSuccess {
  background-color: $primary !important;
}

.MuiLinearProgress-barColorSuccess {
  background-color: #bbb !important;
}

.btn {
  padding: .4rem .8rem;
  font-size: $fontSize1;
  border-radius: 10px;
  font-weight: 400;
  transition: 300ms;
  font-family: $font1;
}

.btn-outline-main {
  background: transparent;
  border: .5px solid $primary;
  color: $primary;

  &.outline-text {
    font-size: 12px;
  }

  &.btn-block {
    display: block;
    width: 100%;
  }

  &.active {
    background: $primary;
    color: $bgColor1;
    border: .5px solid transparent;
  }

  &:hover {
    @extend .active;
  }
}

.text-bold {
  font-weight: 500;
}

.wnitem8 {
  width: 8rem;
  overflow: hidden;
  display: inline-block;
}

.bg-nitem {
  background: #f5f5f5;
}

.text-success2 {
  color: #00BBB1 !important;
}

.sub-heading {
  font-weight: 600;
  font-size: 1.2rem;
}

.nitemtitle {
  font-size: .9rem;
  font-weight: 600;
}

.btn-main {
  background: $primary;
  color: $white;
  border: .5px solid $primary;
  height: 2rem;
  line-height: 0;
  font-size: 13px;

  &.active {
    background: transparent;
    color: $primary;
    border: .5px solid $primary;
  }

  &.btn-block {
    display: block;
    width: 100%;
  }

  &.text-big {
    font-size: 15px;
  }

  &.line-height {
    line-height: normal;
  }

  &:hover {
    @extend .active
  }
}

.btn-color1 {
  @extend .btn-main;
  background: $color1;
  border: .5px solid $color1;

  &.active {
    background: transparent;
    color: $color1;
    border: .5px solid $color1;
  }

  &:hover {
    @extend .active;
  }
}

.h100 {
  height: 100%;
}

.w100 {
  height: 100%;
}

.mt-main {
  margin-top: 2rem;
}

.text-pr {
  color: $primary;
}

.heading {
  font-size: $fontSize3;
  color: $primary;
  font-family: $font1;
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font1 !important;
}

.link {
  text-decoration: none;
  font-size: .9rem;
  font-family: $font2;
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-color-dark {
  color: $fontColor2;

  &:hover {
    color: $fontColor2;
  }
}

.text-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-color-primary {
  @include font-color($primary)
}

.font-color-color {
  @include font-color($color1)
}

.font-color-white {
  @include font-color($white);
}

.font-weight-600 {
  font-weight: 600;
}

.form-select {
  font-size: 12px;
  border-radius: .8rem;
  border: .5px solid $borderColor2;
  background-color: $bgColor1;
  color: $fontColor2;
  font-family: $font2;
  padding: .3rem 2rem .3rem .7rem;

  &.font-small {
    font-size: 13px;
  }
}

.text-small {
  font-size: 12px;
  @extend .font-opens;
}

.Mui-selected, .btn-primary, .MuiButton-containedInfo, .btn-info, .btn-success {
  background-color: $primary !important;
  border: 1px solid $primary !important;
  color: white;
}

.form-control {
  font-size: 13px;
  border-radius: .8rem;
  border: .5px solid $borderColor2;
  background-color: $bgColor1;
  font-family: $font2;
  padding: .4rem 1rem .4rem 1rem;
  font-weight: 500;
  color: $fontColor1;

  &::placeholder {
    color: $fontColor1;
    font-size: 13px;
    font-family: $font2;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    font-family: $font2;
    border: .5px solid $borderColor2;
  }
}

hr {
  height: 1.5px !important;
  background-color: #707070;
}

.alert-color-1 {
  color: $alertColor1;

  &.bg {
    background: $alertColor1;
  }
}

.alert-color-2 {
  color: $alertColor2;

  &.bg {
    background: $alertColor2;
  }
}

.alert-color-3 {
  color: $alertColor3;

  &.bg {
    background: $alertColor3;
  }
}

.alert-color-4 {
  color: $alertColor4;

  &.bg {
    background: $alertColor4;
  }
}

.alert-color-5 {
  color: $alertColor5;

  &.bg {
    background: $alertColor5;
  }
}

.bg-white {
  background: #fff !important;
}