@import "src/main";

.subscription-alert {
  border: .5px solid $borderColor2;
  padding: .7rem 1rem;
  font-size: 13px;
  border-radius: $borderRadius;
  font-weight: 500;
  @extend .font-opens;
}

.logoIcon {
  width: 100%;
  height: 8rem;
  border: .5px solid $borderColor2;
  border-radius: $borderRadius;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .5rem 0;
  font-size: 2rem;
}

.content {
  overflow: hidden;
}

.sidebar {
  padding-left: 0;
  margin-bottom: 0;

  li {
    .nav-link {
      text-decoration: none;
      color: $fontColor2;
      border-bottom: .5px solid $bgColor1;
      padding: .7rem 0;
      border-left: 5px solid transparent;
      font-size: 13px;
      transition: 300ms;
      @extend .font-opens;

      span {
        margin-left: 1rem;
      }

      &.active {
        border-left: 5px solid $primary;
        font-weight: 700;
      }

      &:hover {
        border-left: 5px solid $primary;
      }
    }

    &:last-child {
      .nav-link {
        margin-bottom: 0;
      }
    }
  }
}

@import "src/responsive";