@import "src/main";

.left-heading-content {
  display: inline-block;
}

.right-form-content {
  display: inline-block;
  margin-left: 1rem;
}

.jobs-content {
  .ml-main {
    margin-left: 3.7rem;
  }

  .search-form {
    @include search-form(10rem, $borderColor2, 12px, .8rem 0 0 .8rem);

    .btn {
      border-radius: 0 .8rem .8rem 0;
    }
  }
}

.closeModalViewJob {
  background: $primary;
  color: $white;

  h5 {
    font-size: 1rem;
  }
}

.job-view {
  .content {
    background: $bgColor1;

    p {
      font-size: 13px !important;
      @extend .font-opens;
      font-weight: 500;
    }

    .font-bold {
      font-weight: 700;
    }

    .color-1-link {
      font-size: 13px;
      text-decoration: none;
      color: $color1;
    }

    .title {
      font-size: 1.17rem;
    }

    .logo {
      width: 2.7rem;
      height: 2.7rem;
      border: .5px solid $borderColor2;
      object-fit: contain;
    }

    .text-xs {
      font-size: 12px !important;
    }

    .btn-small {
      font-size: 12px !important;
      padding: .1rem .7rem;
    }
  }

  .warningIcon {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.filter-width {
  width: 9rem;
}

.modal-jobView {
  @include jobViewModal(5rem, 55vw, 80vh, $bgColor1);
  @extend .job-view;
}

.jobPdfViewer {
  height: 50vh;
}

.mailto {
  border: .5px solid $color1;
  color: $color1;
  padding: .3rem 1rem;
  border-radius: 7px;
}

@import "src/responsive";