@import "src/main";

.header {
  position: fixed;
  width: 100%;
  background: $white;
  z-index: 9999;

  .logo {
    width: 6rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .btn-main {
    margin-top: 3px;
    line-height: 1.3;
  }

  .nav-link {
    font-weight: 500;
    color: $fontColor2 !important;
    transition: 300ms;
    font-size: $fontSize1;
    font-family: "Poppins", sans-serif;

    &.active {
      color: $color1 !important;
    }

    &:hover {
      @extend .active;
    }
  }
}

.footer {
  background: $primary;

  p {
    color: $fontColor3;
    font-size: $fontSize1;
  }
}

@import "src/responsive";