@media (min-width: 768px) {
  .ml-recent1 {
    margin-left: 1.3rem;
  }
  .ml-recent2 {
    margin-left: .5rem;
  }
}

@media (min-width: 992px) {
  .modal-jobView {
    margin-top: 2rem;
    @include jobViewModal(5rem, 70rem, 80vh)
  }
}

@media (min-width: 1200px) {
  .container {

  }
}

@media (min-width: 1400px) {
  .main-content .container {
    max-width: 1200px;
  }
  .modal-jobView {
    margin-top: 2rem;
    @include jobViewModal(5rem, 70rem, 80vh)
  }
}

@media (max-width: 1399px) {
  .main-content .container {
    max-width: 1200px;
  }
}

@media (max-width: 1199px) {
  .mx-jobs-main {
    margin: 1rem .7rem 0 3.3rem;
  }
}

@media (max-width: 991px) {
  .top-area {
    height: 100%;
  }
  .modal-jobView {
    margin-top: 2rem;
    @include jobViewModal(4rem, 86vw, 90vh)
  }
}

@media (max-width: 767px) {
}

@media (max-width: 524px) {
  .recent-heading {
    display: block !important;

    .tagline {
      width: 100%;
      text-align: center;
      margin-left: 0 !important;
      margin-top: 1rem;

      .ms-tag {
        margin-left: 0;
      }

      a {
        padding: 0;
        margin: 0 !important;
      }
    }

    .heading {
      text-align: center;
    }
  }
  .modal-jobView {
    margin-top: 2rem;
    @include jobViewModal(4rem, 95vw, 90vh)
  }
  .Toastify__toast-container {
    width: 95vw !important;
    margin: .6rem !important;
  }
}

@media (max-width: 415px) {
}
